import { isSignal } from '@angular/core';
import { isFunction, isNumber } from '../../helpers';
import type { MaybeSignalOrGetter } from './types';

const TARGET_REG_EXP = /^-?\d+\.?\d*/;

/** Get the value of value/signal/getter. */
export function toValue<T>(fn: MaybeSignalOrGetter<T>): T {
  return isSignal(fn) || isFunction(fn) ? fn() : fn;
}

/**
 * Adjust string || number value with unit
 *
 * @example '2px' + 1 = '3px'
 * @example '15em' + (-2) = '13em'
 */
export function adjustWithUnit(target: string | number, delta: number): string | number {
  if (isNumber(target)) return target + delta;

  const value = TARGET_REG_EXP.exec(target)?.[0] ?? '';
  const result = Number.parseFloat(value) + delta;

  return isNumber(result)
    ? result + target.slice(value.length)
    : target;
}