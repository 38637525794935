import { cssUnitAttribute } from '../coercion';
import { isArrayWithLength } from '../helpers';
import type { SubscriptSizing } from '@angular/material/form-field';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';
import { ERROR_MESSAGES, type VmrErrorMessage, type VmrErrorMessageInput } from './error-message';
import {
  input,
  model,
  OnInit,
  inject,
  Component,
  booleanAttribute,
  ChangeDetectionStrategy
} from '@angular/core';

/**
 * Extend this when creating a form component that needs to bind to `formControlName` => `controlName`.
 * Allows for passing a `FromControl` via the `controller` model input for backwards compat.
 */
@Component({
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  host: {
    '[class.vmr-hide-subscript-wrapper]': 'hideSubscript()'
  }
})
export abstract class VmrFormFieldBase implements OnInit {
  /** @internal */
  #parentFormGroup = inject(FormGroupDirective, {optional: true});

  /**
   * `FormControl` for the input.
   * This will always need to be defined, however, consumers have 2 options for setting it:
   * 1). Pass `FormControl` directly to this property.
   * 2). Pass `controlName` (`formControlName`) and allow `controller` to be set via parent `FormGroupDirective`.
   */
  readonly controller = model<FormControl<any>>();

  /** Placeholder text for the input. Default `''`. */
  readonly placeholder = input<string>('');

  /** Label for the input. Default `undefined`. */
  readonly label = input<string | undefined>();

  /** Hint subscript text for the input. Default `undefined`. */
  readonly hintText = input<string | undefined>();

  /**
   * Whether the `<mat-form-field>` should reserve space for one line of hint/error text (default)
   * or to have the spacing grow from 0px as needed based on the size of the hint/error content.
   * Note that when using dynamic sizing, layout shifts will occur when hint/error text changes.
   */
  readonly subscriptSizing = input<SubscriptSizing>('fixed');

  /**
   * This is the same as Angular's `formControlName`.
   * Can be used to bind a `FormControl` to the underlying input element.
   * If this is not specificied, a `FormControl` needs to be passed to the `controller` input.
   */
  readonly controlName = input<string | undefined>(undefined);

  /**
   * Class that will be applied to the control element - e.g. `<input>`, `<textarea>`.
   * Defaults to `undefined`.
   */
  readonly controlClass = input<string | undefined>(undefined);

  /** Class that will be applied to the parent `mat-form-field` element. Defaults to `undefined`. */
  readonly formFieldClass = input<string | undefined>(undefined);

  /**
   * Quick way to directly set the parent `mat-form-field` element width via the width style attribute.
   * Defaults to `null`.
   */
  readonly formFieldWidth = input(null, {
    transform: cssUnitAttribute
  });

  /**
   * Whether or not to apply a class that sets the parent `mat-form-field` element subscript to `display: none`.
   * Defaults to `false`.
   */
  readonly hideSubscript = input<boolean, unknown>(false, {
    transform: booleanAttribute
  });

  /**
   * Should use the error handling instructions defined on `ERROR_MESSAGES`.
   * In some cases, you may want to content project your own error validation rules
   * and the built-ins may collide with them. Default is `true`.
   */
  readonly useBuiltInErrors = input<boolean, unknown>(true, {
    transform: booleanAttribute
  });

  /**
   * Override and/or additional internal error handling configuration items.
   * When there are matches on `errorType` keys between `errors` and `ERROR_MESSAGES`, the definition from `errors`is used.
   * Input value also accepts properties of `errorType` and `errorMessage` as well to accomodate the legacy interface.
   * Defaults to `undefined` (unmodified ERROR_MESSAGES object will be used).
   *
   * TODO: the override array should first replace items in source where type matches, then add unique types to the start
   */
  readonly errors = input<VmrErrorMessage[], VmrErrorMessageInput[] | undefined>(ERROR_MESSAGES, {
    transform: (value?: VmrErrorMessageInput[]): VmrErrorMessage[] => {
      return isArrayWithLength(value)
        ? value
            .map(
              (x): VmrErrorMessage => ({
                type: x.type || x.errorType || '',
                message: x.message || x.errorMessage || ''
              })
            )
            .filter(({ type }) => !!type?.trim())
            .concat(ERROR_MESSAGES)
        : ERROR_MESSAGES;
    }
  });

  ngOnInit(): void {
    // TODO: explore adding custom error handling - e.g. to alert the use of formControlName => controlName
    if (!this.controller()) {
      this.controller.set(
        (this.#parentFormGroup?.form.get(this.controlName() || '') as FormControl) ?? new FormControl()
      );
    }
  }

  /**
   * Computes the error message to display when the control is touched + invalid.
   */
  abstract getErrorText(): string;
}