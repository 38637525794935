export interface VmrErrorMessage {
  type: string;
  message: string;
}

/**
 * Flexibile type for `errors` input transform param.
 * Ensures backwards compat with legacy API where property keys had "error" prefix.
 */
export type VmrErrorMessageInput = Partial<VmrErrorMessage> & {
  errorType?: string;
  errorMessage?: string;
};

/**
 * Default error message configuration.
 * Entries can be added/overriden via the `errors` input - implemented on VmrFormFieldBase super class.
 * '$$' in the message property is string.replaced with a provided value.
 */
export const ERROR_MESSAGES: VmrErrorMessage[] = [
  {
    type: 'required',
    message: '$$ is required'
  },
  {
    type: 'min',
    message: 'Please enter a number greater than $$'
  },
  {
    type: 'max',
    message: 'Please enter a number less than $$'
  },
  {
    type: 'minlength',
    message: 'Please enter more than $$ characters'
  },
  {
    type: 'maxlength',
    message: 'Please enter less than $$ characters'
  },
  {
    type: 'email',
    message: 'Please enter a valid email address'
  },
  {
    type: 'pattern',
    message: 'Invalid $$'
  }
];