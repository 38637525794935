/**
 * Check if parameter is typeof `string`
 * @param x
 */
export const isString = (x: unknown): x is string => typeof x === 'string';

/**
 * Check if parameter is typeof `number`
 * @param x
 */
export const isNumber = (x: unknown): x is number => typeof x === 'number';

/**
 * Check if parameter is typeof `boolean`
 * @param x
 */
export const isBoolean = (x: unknown): x is boolean => typeof x === 'boolean';

/**
 * Check if parameter an array object with length >= 1
 * @param x
 */
export const isArrayWithLength = (x: unknown): x is Array<unknown> => Array.isArray(x) && !!x.length;

/**
 * Check if parameter is typeof `function`
 * @param x
 */
export const isFunction = (x: unknown): x is ((...args: any[]) => any) => typeof x === 'function';