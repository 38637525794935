import { DestroyRef, inject, signal, type Signal } from '@angular/core';
import { getDefaultWindow, hasMatchMediaSupport } from '../helpers';
import { toValue } from './internals';

/**
 * Reactive Media Query.
 * See https://ui.vermeer.com/signals/breakpoint-observer.
 *
 * @param query Media query to match - e.g. (max-width: 991px).
 * @param window The window instance to bind event listeners to.
 * @returns A read-only signal indicating whether the media query matches.
 */
export function observeMediaQuery(
  query: string | Signal<string> | (() => string),
  window: (Window & typeof globalThis) | undefined = getDefaultWindow()
): Signal<boolean> {
  let mediaQuery: MediaQueryList | undefined;

  const destroyRef = inject(DestroyRef);
  const matches = signal<boolean>(false);
  const isMatchSupported = hasMatchMediaSupport(window);
  const matchMediaHandler = (e: MediaQueryListEvent) => matches.set(e.matches);

  if (isMatchSupported) {
    mediaQuery = window.matchMedia(toValue(query));
    matches.set(mediaQuery.matches);
    mediaQuery.addEventListener('change', matchMediaHandler);
  }

  destroyRef.onDestroy(() => {
    mediaQuery?.removeEventListener('change', matchMediaHandler);
  });

  return matches.asReadonly();
}